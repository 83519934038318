<template>
  <v-container
    v-if="company"
    fluid
    class="pa-0"
    :style="`max-height: ${($vuetify.breakpoint.height - 130).toString() + 'px'};`"
  >
    <v-overlay
      v-if="loading"
      absolute
      :value="loading"
      opacity="1"
      color="white"
      class="full-screen"
      text-xs-center
    >
      <div class="d-flex flex-column justify-center align-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        />
        <img
          src="@/assets/Lavirra_logo_SVG.svg"
          height="28px"
          width="auto"
          class="mt-6"
        >
        <span
          class="mt-4 ml-2"
          :style="`font-size: 15px; color: ${$vuetify.theme.themes.light.primary};`"
        >
          {{ message }}
        </span>
      </div>
    </v-overlay>
    <v-container
      fluid
      class="px-3 mt-3"
      style="position: sticky; top: 15px; z-index: 5;"
    >
      <v-row
        class="mt-n7"
        justify="start"
        align="center"
      >
        <v-sheet
          flat
          color="lightBackground"
          style="width: 100%;"
        >
          <v-row
            v-if="company"
            align="center"
            justify="start"
            dense
            no-gutters
            class="my-4"
          >
            <v-text-field
              v-model="search"
              :solo-inverted="soloInverted"
              :solo="solo"
              prepend-inner-icon="mdi-magnify"
              rounded
              :label="$t('members|search')"
              dense
              class="ml-4 shrink"
              hide-details
              @focus="soloInverted = true; solo = false"
              @blur="soloInverted = false; solo = true"
            />
            <v-spacer />
            <v-btn
              v-if="permissionRuleAddCompanyMember()"
              color="primary"
              rounded
              small
              :disabled="company.members.length >= company.seats"
              @click="openAddMemberDialog"
              @mouseover="hoverMoreMembers = true"
              @mouseleave="hoverMoreMembers = false"
            >
              <v-icon
                left
              >
                {{ hoverMoreMembers ? 'mdi-plus-circle-outline' : 'mdi-account-plus' }}
              </v-icon>
              {{ $t('members|add_member') }}
            </v-btn>
            <v-btn
              v-if="company.subscriptionStatus === 'unpaid'"
              color="primary"
              rounded
              small
              @click="payTheCompanySubscription()"
            >
              {{ $t('payments|subscription_payment') }}
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-4 ml-2"
                  color="primary"
                  rounded
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    small
                    color="white"
                    class="pr-2"
                  >
                    mdi-account-settings
                  </v-icon>
                  {{ $t('members|user_management') }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in seatManegamentItems"
                  :key="index"
                  @click="seatManagementAction(item.action)"
                >
                  <v-list-item-icon>
                    <v-icon
                      color="primary"
                    >
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    class="ml-n4"
                  >
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <AddMember
              ref="AddMember"
            />
          </v-row>
          <v-progress-linear
            :value="getValueProgress(company, company.members.length)"
            height="40"
          >
            <template v-slot:default="{ value }">
              <strong
                class="white--text ml-4"
              >
                {{ company.members.length + '/' + company.seats + ' ' + $t('members|members') }}
              </strong>
              <v-spacer />
              <!-- <v-btn
                class="white--text mr-8"
                :color="company.members.length === company.seats ? 'white' : 'primary'"
                rounded
                outlined
                x-small
                link
                :disabled="disableButton(actualMember)"
                :to="`/buy-member-seats`"
                @mouseover="hoverMoreStorage = true"
                @mouseleave="hoverMoreStorage = false"
              >
                <v-icon
                  small
                  class="pr-2"
                >
                  {{ hoverMoreStorage ? 'mdi-plus-circle-outline' : 'mdi-account' }}
                </v-icon>
                {{ $t('members|get_more_seats') }}
              </v-btn> -->
            </template>
          </v-progress-linear>
        </v-sheet>
      </v-row>
    </v-container>
    <v-card
      class="members-table-wrapper mt-2"
      flat
    >
      <v-data-table
        :headers="tableHeaders"
        :items="itemsToShowInTable"
        :search="search"
        :loading="loading"
        :loading-text="$t('loading|please_wait')"
        fixed-header
        :height="($vuetify.breakpoint.height - 300).toString() + 'px'"
      >
        <template v-slot:item.accountName="{ item }">
          <v-avatar
            size="35"
            color="primary"
            class="mx-4 my-2"
          >
            <img
              v-if="item.avatar"
              :src="`${cfg.baseURL}downloadAvatar/${item._id}/${item.avatar.raw.filename}`"
            >
            <v-icon
              v-if="item.invitation"
              size="28"
              dark
            >
              {{ messageIcon }}
            </v-icon>
            <v-icon
              v-else
              size="30"
              dark
            >
              mdi-account-circle
            </v-icon>
          </v-avatar>
          <span v-if="item.invitation">
            <span v-if="item.actualStatus === 'pending'">
              {{ item.email.split('@')[0] }}({{ $t('common|pending_invitation') }})
            </span>
            <span v-if="item.actualStatus === 'declined'">
              {{ item.email.split('@')[0] }}({{ $t('common|invitation_declined') }})
            </span>
          </span>
          <span v-else>
            {{ item.accountName }}
          </span>
        </template>
        <template v-slot:item.userRole="{ item }">
          <v-chip
            v-if="item.userRole === 'Admin' || item.userRole === 'Admin(Owner)'"
            small
            color="primary"
          >
            {{ translateUserRole(item.userRole) }}
          </v-chip>
          <v-chip
            v-else
            small
            color="secondary"
          >
            {{ translateUserRole(item.userRole) }}
          </v-chip>
        </template>
        <template
          v-slot:item.actions="{ item, idx }"
        >
          <v-menu
            v-if="permissionRule() && !item.invitation"
            :close-on-content-click="false"
            :nudge-width="250"
            offset-x
            @input="onMenuToggle"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                {{ accountIcon }}
              </v-icon>
            </template>
            <v-card
              max-width="350"
            >
              <v-list>
                <v-list-item>
                  <v-list-item-avatar
                    size="60"
                    color="primary"
                  >
                    <img
                      v-if="item.avatar"
                      :src="`${cfg.baseURL}downloadAvatar/${item._id}/${item.avatar.raw.filename}`"
                    >
                    <v-icon
                      v-else
                      color="white"
                      size="55"
                    >
                      mdi-account-circle
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ $t('members|member') }}: {{ item.accountName }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ $t('members|added_on') }}: {{ item.createdAt }}</v-list-item-subtitle>
                    <v-list-item-title
                      class="mt-2"
                    >
                      {{ $t('actions|change_member_role') }}:
                    </v-list-item-title>
                    <v-row
                      align="center"
                    >
                      <v-col
                        class="d-flex"
                        cols="12"
                        sm="9"
                      >
                        <v-select
                          v-model="roleSelection"
                          :items="userRolesList"
                          :label="translateUserRole(item.userRole)"
                          item-text="text"
                          item-value="type"
                          solo-inverted
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider />
              <v-list>
                <v-list-item>
                  <v-alert
                    border="left"
                    colored-border
                    type="warning"
                    elevation="2"
                    class="mt-2"
                  >
                    {{ $t('warning|change_user_role') }}
                  </v-alert>
                </v-list-item>
              </v-list>
              <v-card-actions class="mt-n2">
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  @click="changeUserRole(item)"
                >
                  {{ $t('actions|save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-icon
            color="primary"
            :disabled="disableButton(item, idx)"
            @click="deleteMember(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <template>
      <StripeCheckout
        ref="checkoutRef"
        :pk="publishableKey"
        :session-id="sesId"
        @loading="v => loading = v"
      />
    </template>
  </v-container>
</template>

<script>
import moment from 'moment'
import AddMember from '../../components/dialogs/AddMember.vue'
import { mapState, mapActions } from 'vuex'
import { mdiAccountConvertOutline, mdiEmailNewsletter, mdiAccountPlus, mdiAccountMinus } from '@mdi/js'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import autobahn from 'autobahn'
import cfg from '@/config'
const createdAtFormat = date => moment(date).format('DD/MM/YYYY')

export default {
  components: {
    AddMember,
    StripeCheckout
  },
  data () {
    return {
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      sesId: null,
      loading: false,
      error: null,
      dialog: false,
      search: '',
      roleSelection: null,
      accountIcon: mdiAccountConvertOutline,
      messageIcon: mdiEmailNewsletter,
      cfg,
      soloInverted: false,
      solo: true,
      hoverMoreStorage: false,
      hoverMoreMembers: false,
      message: ''
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    }),
    actualMember () {
      return this.company.members.find(mem => mem.member_id._id === this.account._id)
    },
    userRolesList () {
      let roles = []
      if (this.account && this.account.currentUserRole && this.account.currentUserRole === 'adminOwner') {
        roles = [
          {
            type: 'Regular',
            text: this.$t('members|regular')
          },
          {
            type: 'Advanced',
            text: this.$t('members|advanced')
          },
          {
            type: 'Admin',
            text: this.$t('members|admin')
          },
          {
            type: 'Admin-owner',
            text: this.$t('members|admin_owner')
          }
        ]
      } else {
        roles = [
          {
            type: 'Regular',
            text: this.$t('members|regular')
          },
          {
            type: 'Advanced',
            text: this.$t('members|advanced')
          },
          {
            type: 'Admin',
            text: this.$t('members|admin')
          }
        ]
      }
      return roles
    },
    itemsToShowInTable () {
      let items = []
      if (!this.company) return items
      for (let i = 0; i < this.company.members.length; i++) {
        const acc = this.company.members[i]
        let userRole = ''
        if (this.company.userRoles.basic.includes(acc.member_id._id)) {
          userRole = 'Regular'
        }
        if (this.company.userRoles.advanced.includes(acc.member_id._id)) {
          userRole = 'Advanced'
        }
        if (this.company.userRoles.admin.includes(acc.member_id._id)) {
          userRole = 'Admin'
        }
        if (this.company.userRoles.adminOwner.includes(acc.member_id._id)) {
          userRole = 'Admin(Owner)'
        }
        let createdAt = ''
        let avatar = null
        createdAt = acc.createdAt
        if (acc.member_id.avatar) {
          avatar = acc.member_id.avatar
        }
        let accountInfo = {
          accountName: acc.member_id.accountData && acc.member_id.accountData.firstName && acc.member_id.accountData.lastName ? acc.member_id.accountData.firstName + ' ' + acc.member_id.accountData.lastName : acc.member_id.accountName,
          email: acc.member_id.email,
          createdAt: createdAtFormat(createdAt),
          userRole: userRole,
          _id: acc.member_id._id,
          companyId: acc.member_id.companyId,
          avatar: avatar || null
        }
        items.push(accountInfo)
      }
      for (let idx = 0; idx < this.company.invitedUsers.length; idx++) {
        const invitedUser = this.company.invitedUsers[idx]
        const accountInfo = {
          ...invitedUser,
          avatar: null,
          invitation: true
        }
        items.push(accountInfo)
      }
      return items
    },
    tableHeaders () {
      const headers = [
        {
          text: this.$t('members|members'),
          align: 'start',
          value: 'accountName',
          width: '550'
        },
        {
          text: this.$t('common|email'),
          align: 'start',
          value: 'email',
          sortable: false
        },
        {
          text: this.$t('members|user_role'),
          align: 'start',
          value: 'userRole'
        },
        {
          text: this.$t('members|date_added'),
          align: 'start',
          value: 'createdAt'
        }
      ]
      if (this.company && ((this.company.userRoles.advanced.includes(this.account._id)) || (this.company.userRoles.adminOwner.includes(this.account._id)) || this.company.userRoles.admin.includes(this.account._id))) {
        headers.push({ text: this.$t('actions|actions'), value: 'actions', sortable: false, align: 'end' })
      }
      return headers
    },
    seatManegamentItems () {
      return [
        {
          title: this.$t('members|get_more_seats'),
          icon: mdiAccountPlus,
          action: 'add'
        },
        {
          title: this.$t('members|delete_user_seats'),
          icon: mdiAccountMinus,
          action: 'removal'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      deleteThisMember: 'company/deleteMember',
      updateCompany: 'company/updateCompany',
      cancelCompanyMemberInvitation: 'company/cancelCompanyMemberInvitation'
    }),
    onMenuToggle (menuIsOpen) {
      if (!menuIsOpen) this.roleSelection = null
    },
    translateUserRole (input) {
      if (input === 'Admin') return this.$t('members|admin')
      if (input === 'Admin(Owner)') return this.$t('members|admin_owner')
      if (input === 'Regular') return this.$t('members|regular')
      if (input === 'Advanced') return this.$t('members|advanced')
    },
    permissionRule () {
      if (this.company) {
        const searchResult = this.company.userRoles.adminOwner.includes(this.account._id)
        if (searchResult) {
          return searchResult
        }
        const secondSearchResult = this.company.userRoles.admin.includes(this.account._id)
        if (secondSearchResult) {
          return secondSearchResult
        }
      }
    },
    permissionRuleAddCompanyMember () {
      if (this.company) {
        if (this.company.subscriptionStatus === 'unpaid') {
          return false
        }
        const searchResult = this.company.userRoles.adminOwner.includes(this.account._id)
        if (searchResult) {
          return searchResult
        }
        const secondSearchResult = this.company.userRoles.admin.includes(this.account._id)
        if (secondSearchResult) {
          return secondSearchResult
        }
        const thirdSearchResult = this.company.userRoles.advanced.includes(this.account._id)
        if (thirdSearchResult) {
          return thirdSearchResult
        }
      }
    },
    getValueProgress (company, membersAmount) {
      return membersAmount / company.seats * 100
    },
    async changeUserRole (memberInfo) {
      const _id = memberInfo.companyId
      if (this.roleSelection === 'Admin-owner') this.roleSelection = 'adminOwner'
      const payload = {
        member_id: memberInfo._id,
        userRole: this.roleSelection,
        oldUserRoleBevorChange: memberInfo.userRole
      }
      try {
        await this.updateCompany({ _id, payload })
      } catch (e) {
        console.error(e, 'error')
      }
    },
    async deleteMember (member) {
      let res = null
      if (member.invitation) {
        res = await this.$dialog.confirm({
          title: this.$t('common|warning'),
          text: this.$t('warning|you_want_to_cancel_invitation')
        })
      } else if (!member.invitation && member._id === this.account._id) {
        res = await this.$dialog.confirm({
          title: this.$t('common|warning'),
          text: this.$t('warning|are_you_sure_you_want_to_leave') + ' ' + this.company.companyData.companyName
        })
      } else {
        res = await this.$dialog.confirm({
          title: this.$t('common|warning'),
          text: this.$t('members|warning')
        })
      }
      if (res && !member.invitation) {
        const companyId = member.companyId
        const _id = member._id
        try {
          await this.deleteThisMember({ _id, companyId })
        } catch (e) {
          console.error(e, 'error')
        }
      }
      if (res && member.invitation) {
        try {
          const _id = this.company._id
          const payload = member
          await this.cancelCompanyMemberInvitation({ _id, payload })
        } catch (e) {
          console.error(e, 'error')
        }
      }
    },
    disableButton (member, index) {
      const adminOwner = this.company.userRoles.adminOwner.includes(this.account._id)
      const admin = this.company.userRoles.admin.includes(this.account._id)
      const advanced = this.company.userRoles.advanced.includes(this.account._id)
      const basic = this.company.userRoles.basic.includes(this.account._id)

      if (adminOwner) return false
      else if (admin && member.userRole !== 'Admin(Owner)') return false
      else if (basic && this.account._id === member._id) return false
      else if (advanced && this.account._id === member._id) return false
      else return true
    },
    openAddMemberDialog () {
      this.$refs.AddMember.dialog = true
    },
    payTheCompanySubscription () {
      this.message = this.$t('message|proceed_to_checkout')
      this.loading = true
      let result = null
      let n = 0
      try {
        var connection = new autobahn.Connection({
          url: process.env.VUE_APP_CROSSBAR_PATH,
          realm: process.env.VUE_APP_CROSSBAR_REALM
        })
        connection.onopen = async (session) => {
          const makeSessionCall = async (account, memberFields) => {
            const _res = await session.call('lavvira.stripe_controller.create_payment_session',
              [
                account.companyId,
                'company',
                memberFields
              ])
            return _res
          }
          // try 5 times to get the sessionId from backend
          while (n <= 5) {
            result = await makeSessionCall(this.account, this.company.seats)
            const parsedRes = JSON.parse(result)
            if (parsedRes && !parsedRes.stripe_error) n = 6
            else n++
          }
          const res = JSON.parse(result)
          if (res && !res.stripe_error) {
            const res = JSON.parse(result)
            this.sesId = res.session_id
            setTimeout(() => {
              this.loading = false
              this.$refs.checkoutRef.redirectToCheckout()
            }, 3000)
          } else {
            this.loading = false
            this.addToast({
              title: this.$t('error|something_went_wrong'),
              color: 'white',
              snackbarColor: 'error'
            })
            this.$router.push('/members')
          }
        }
        connection.onclose = function (reason, details) {
          // handle connection lost
          console.log(reason, details, 'connection.onclose')
        }
        connection.open()
      } catch (e) {
        console.error(e, 'WAMP ERROR')
      }
    },
    async seatManagementAction (action) {
      if (action && action === 'add') this.$router.push('/buy-member-seats')
      if (action && action === 'removal') {
        if (this.company.members.length === this.company.seats) {
          await this.$dialog.warning({
            title: `${this.$t('common|warning')}`,
            text: `${this.$t('expressions|first_remove_member_then_seat')}`,
            showClose: false,
            width: '750',
            actions: [{
              text: this.$t('actions|close'), color: 'primary'
            }]
          })
        } else if (this.company.members.length < this.company.seats) {
          console.log('OMJJ')
          this.$router.push('/delete-member-seats')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .members-table-wrapper {
    width: 100%;
  }
</style>
